<template>
  <div>
    <van-nav-bar title="日程详情" />
    <van-cell>
      <template #title>
        <span> {{ data.title }}</span>
      </template>
      <!-- <template #default>
        <van-button type="default">编辑</van-button>
      </template> -->
      <template #extra>
        <van-button type="default" @click="remindDel" :disabled="delDis">删除</van-button>
      </template>
      <template #label>
        <span>{{ data.label }}</span>
      </template>
    </van-cell>
    <van-cell title="设置提醒">
      <template #default>
        <van-radio-group v-model="radio">
          <van-radio name="1">消息推送</van-radio>
        </van-radio-group>
      </template>
      <template #extra>
        <span style="color: #1989fa">完成</span>
      </template>
    </van-cell>
    <logHis :userId="userId" />
  </div>
</template>
<script>
import logHis from "../visitLog/compents/logHis.vue";
export default {
  components: { logHis },
  data() {
    return {
      id: "",
      data: { title: "", label: "" },
      radio: "1",
      userId: "",
      delDis:false
    };
  },
  methods: {
    //日程删除
    remindDel() {
      this.$axios
        .get(`${this.$base}/fission/wechat/deleteRemind?id=${this.id}`)
        .then((res) => {
          if (res.code === "200") {
            this.delDis=true
            this.$toast("操作成功");
          } else {
            this.$toast("操作失败");
          }
        });
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.userId = this.$route.query.userId;
  },
  mounted() {
    this.$axios
      .get(`${this.$base}/fission/wechat/queryRemind?id=${this.id}`)
      .then((res) => {
        if (res.code === "200") {
          this.data.title = res.value.logValue;
          this.data.label = res.value.remindTime;
        }
      });
  },
};
</script>
